<template>
    <div class="container">
        <div class="card subpage">
            <div class="has-text-centered" style="display: flex; justify-content: space-between; align-items: center;">
                <div class="button-back-left"><b-button tag="router-link" to="/en/" type="is-primary" rounded icon-left="arrow-left"></b-button></div>
                <div class="subpage-heading is-family-secondary">
                    <p class="has-text-primary">FINDCDE.COM</p>
                    <h1 class="is-size-3 has-text-weight-semibold">BIM methods</h1>
                </div>
                <div class="button-back-right" style="visibility: hidden;"><b-button tag="router-link" to="/en/" type="is-primary" rounded icon-left="arrow-left"></b-button></div>
            </div>

            <hr>

            <div>
                <p>The BIM method is the future of Czech building construction in the digital age. One of the key pillars to its successful implementation in practice is the deployment and operation of a common data environment (CDE), in which a complete information model of the structure or digital twin is created. Without a CDE, it is impossible to fully exploit the advantages offered by digitisation, especially higher efficiency, speed and lower error rate. The sharing of information in one place, accessible from anywhere, is essential in digitised processes. Indeed, the common data environment is the bearer of the “one truth”.</p>
                <p class="mt-4">Within the Czech Republic, the organisation also responsible for issuing methods is the Czech Standardization Agency. It BIM Concept department was the one that issued selected methods concerning CDE.</p>
                <p class="mt-4">
                    <a href="https://www.koncepcebim.cz/uploads/inq/files/Spole%C4%8Dn%C3%A9%20datov%C3%A9%20prost%C5%99ed%C3%AD%20%28CDE%29%20zaveden%C3%AD%20a%20vyu%C5%BE%C3%ADv%C3%A1ni%20v%20organizaci%20VZ_Agentura%20%C4%8CAS.pdf" target="_blank"> Common data environment (CDE) – Implementation and use in the organisations of public contractors </a> <br>
                    <a href="https://www.koncepcebim.cz/uploads/inq/files/Spole%C4%8Dn%C3%A9%20datov%C3%A9%20prost%C5%99ed%C3%AD%20%28CDE%29%20-%20p%C5%99ehled%20atribut%C5%AF%20pro%20v%C3%BDb%C4%9Br_Agentura_%C4%8CAS.pdf" target="_blank"> Common data environment (CDE) – Overview of attributes for selection </a>
                </p>
                <p class="mt-4">Deployment of a common data environment (CDE) system and its interlinking with the organisation’s structure, style of work and processes is fundamentally a project of digital transformation. However, it also applies that the prepared Act on Information Models, Information and Digital Modelling (BIM Act), which must come into effect by 1 July 2023, expressly speaks about the use of CDE. This means that all the contracting authorities of public contracts worth more than 150 million must adopt this change.</p>
                <div class="has-text-centered my-4">
                    <img :src="require('@/assets/bimkoncepce.png')" alt="BIM Koncepce 2022" style="padding: 0 20px; width: 250px; margin-top: 0;">
                    <img :src="require('@/assets/cas.png')" alt="Česká agentura pro standardizaci" style="padding: 0 20px; width: 250px; margin-top: 0;">
                </div>

                <div class="has-text-centered">
                    <b-button tag="router-link" to="/en/" type="is-primary" rounded icon-left="arrow-left">Go back</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'BIM_Methods',
  metaInfo: {
    title: 'BIM Methods',
    titleTemplate: '%s | NajdiCDE.cz',
    meta: [
      { name: 'description', content: 'The BIM method is the future of Czech building construction in the digital age. One of the key pillars to its successful implementation in practice is the deployment and operation of a common data environment (CDE).'},
      { name: 'keywords', content: 'CDE, NajdiCDE, BIM, czBIM, software, analýza CDE, srovnání CDE, BIM metodiky'}
    ]
  }
}
</script>

<style scoped>

</style>