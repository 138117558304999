<template>
<div> 
  <div class="container mt-6">
    <div class="has-text-centered mb-4">
      <router-link to="/en/">
        <img :src="require('@/assets/bim-logo.svg')" alt="Logo" class="cde-header-logo mb-4 has-text-centered mr-6" style="height: 50px">
        <img :src="require('@/assets/buildingsmart.png')" alt="Logo" class="cde-header-logo mb-4 has-text-centered" style="height: 50px">
      </router-link>
    </div>
    
    <!-- <p class="has-text-centered is-size-3 is-family-secondary has-text-weight-medium mb-4 mx-4"><span v-for="(index, sw) in sortedQuerySW" :key="index">{{querySW[sw]}} <i :class="'fas fa-'+checkVerified(data[querySW[sw]].verified)" v-tooltip="data[querySW[sw]].verified ? 'Verifikováno' : 'Není verifikováno'"></i> <span v-if="sw != querySW.length - 1" class="has-text-primary"> + </span></span></p> -->
    <p class="has-text-centered is-size-3 is-family-secondary has-text-weight-medium mb-4 mx-4">
      <span v-for="(index, sw) in sortedQuerySW" :key="index">
        <span v-tooltip="checkVerifiedSW(data[querySW[sw]].verified)" :class="'is-'+checkVerifiedSW(data[querySW[sw]].verified)">{{querySW[sw]}}</span>
        <!-- <i :class="'fas fa-'+checkVerified(data[querySW[sw]].verified)" v-tooltip="data[querySW[sw]].verified ? 'Verifikováno' : 'Není verifikováno'"></i>  -->
        <span v-if="sw != querySW.length - 1" class="has-text-primary"><i class="fas fa-times mx-2 has-icon-x"></i></span>
      </span>
    </p>

    <b-field position="is-centered" id="btn-group" class="mb-4">
      <p class="control">
        <b-button type="is-primary" v-tooltip.left="'Settings'" @click="settings()"><i class="fas fa-wrench"></i></b-button>
      </p>
      <p class="control">
        <b-button type="is-primary" v-tooltip.top="'Share'" @click="share()"><i class="fas fa-share-alt"></i></b-button>
      </p>
      <p class="control">
        <b-button type="is-primary" v-tooltip.right="'Print or save as PDF'" @click="print()"><i class="fas fa-print"></i></b-button>
      </p>
    </b-field>

    <hr />

    <div class="table-container">
      <table class="table is-fullwidth is-hoverable">
          <thead class="has-background-primary">
              <tr id="table_header_software">
                  <th rowspan="2" class="has-text-white has-text-centered" style="vertical-align: middle"><div class="is-size-3" style="font-weight: 200; margin: 0;">find<span style="font-weight: 500!important;" class="">CDE</span>.com</div></th>
                  <template v-if="hidden != 72"><th style="border-left: 2px solid white; padding: 10px 15px 10px 15px!important; vertical-align: middle" class="has-text-centered has-text-white" v-for="(index, sw) in sortedQuerySW" :key="index" colspan="4">{{querySW[sw]}} <i :class="'fas fa-'+checkVerified(data[querySW[sw]].verified)" v-tooltip="checkVerifiedSW(data[querySW[sw]].verified)"></i></th></template>
              </tr>
              <tr id="table_header_platform" v-if="hidden != 72">
                  <template v-for="index in sortedQuerySW.length">
                      <th style="border-left: 2px solid white" :key="'a'+index" class="has-text-centered has-text-white table-header-platform" v-tooltip.bottom="'In common'"> <i class="fas fa-compress-arrows-alt"></i> </th>
                      <th :key="'b'+index" class="has-text-centered has-text-white table-header-platform" v-tooltip.bottom="'Browser'"> <i class="fas fa-globe"></i> </th>
                      <th :key="'c'+index" class="has-text-centered has-text-white table-header-platform" v-tooltip.bottom="'Desktop'"> <i class="fas fa-desktop"></i> </th>
                      <th :key="'d'+index" class="has-text-centered has-text-white table-header-platform" v-tooltip.bottom="'Phone'"> <i class="fas fa-mobile"></i> </th>
                  </template>
              </tr>
          </thead>
          <tbody>
              <template v-for="(index, otazka) in otazky">
                <template v-if="otazka == 40">
                  <tr :key="'infraNadpis'+index"><td :colspan="querySW.length*4+1" class="table-nadpis table-nadpis-sticky table-nadpis-open" id="nadpisInfrastruktura" @click="hideInfra()">Infrastructure <b-tag class="ml-2" type="is-warning">New!</b-tag>
                    </td>
                  </tr>
                  <tr :id="'infra'+index" v-for="(otazkaInfra, index) in otazkyInfra" :key="'infra'+index">
                    <td>{{otazkaInfra[0]['Název']}}</td>
                    <template v-for="index2 in sortedQuerySW.length">
                        <td class="table-border-left" :key="'a'+index2" style="text-align: center!important;" v-html='parseData(data[sortedQuerySW[index2-1]]["infra"][index]["Společné"])'></td>
                        <td :key="'b'+index2" style="text-align: center!important;" v-html='parseData(data[sortedQuerySW[index2-1]]["infra"][index]["Web"])'></td>
                        <td :key="'c'+index2" style="text-align: center!important;" v-html='parseData(data[sortedQuerySW[index2-1]]["infra"][index]["Desktop"])'></td>
                        <td :key="'d'+index2" style="text-align: center!important;" v-html='parseData(data[sortedQuerySW[index2-1]]["infra"][index]["Mobil"])'></td>
                    </template>
                  </tr>
                </template>
                <tr :key="'nadpis'+otazka" v-if="novyradek.includes(parseInt(otazka))" @click="hideCategory(parseInt(otazka), 'nadpis'+otazka)">
                  <td :colspan="querySW.length*4+1" class="table-nadpis table-nadpis-sticky table-nadpis-open" :id="'nadpis'+otazka">{{novyradek_nadpis[parseInt(otazka)]}}</td>
                </tr>
                <tr :key="otazka" :id="'otazka'+otazka" v-if="otazka != 62 && otazka != 63 && otazka != 64">
                  <td>{{index[0].Název}}</td>
                  <template v-for="index2 in sortedQuerySW.length">
                      <td class="table-border-left" :key="'a'+index2" v-html='parseData(data[sortedQuerySW[index2-1]]["data"][otazka]["Společné"])' style="text-align: center!important;"></td>
                      <td :key="'b'+index2" v-html='parseData(data[sortedQuerySW[index2-1]]["data"][otazka]["Web"])' style="text-align: center!important;"></td>
                      <td :key="'c'+index2" v-html='parseData(data[sortedQuerySW[index2-1]]["data"][otazka]["Desktop"])' style="text-align: center!important;"></td>
                      <td :key="'d'+index2" v-html='parseData(data[sortedQuerySW[index2-1]]["data"][otazka]["Mobil"])' style="text-align: center!important;"></td>
                  </template>
                </tr>
              </template>
          </tbody>
      </table>
    </div>
    <table class="table is-fullwidth is-hoverable mt-6" style="border-collapse: collapse; margin-bottom: 0;" >
      <tbody>
        <tr>
          <td class="table-nadpis table-nadpis-open" :colspan="querySW.length*4+1" @click="hideTable('formats')" id="table-formats">Which formats can be opened and displayed directly in the CDE?</td>
        </tr>
        <template v-for="index in sortedQuerySW.length" id="helloThere">
          <tr :key="'a'+index" class="table-formats-row">
            <!-- {{data[sortedQuerySW[index-1]].web}} -->
            <td>{{sortedQuerySW[index-1]}} - Web</td>
            <td class="has-text-right" style="width: 75%" v-html='parseFormat(data[sortedQuerySW[index-1]].data[63]["Web"])'></td>
          </tr>

          <tr :key="'b'+index" class="table-formats-row">
            <td>{{sortedQuerySW[index-1]}} - Desktop</td>
            <td class="has-text-right" style="width: 75%" v-html='parseFormat(data[sortedQuerySW[index-1]].data[63]["Desktop"])'></td>
          </tr>

          <tr :key="'c'+index" :class="index != sortedQuerySW.length ? 'table-formaty-border' : ''" class="table-formats-row">
            <td>{{sortedQuerySW[index-1]}} - Mobil</td>
            <td class="has-text-right" style="width: 75%" v-html='parseFormat(data[sortedQuerySW[index-1]].data[63]["Mobil"])'></td>
          </tr>
        </template>
      </tbody>
    </table>

    <table class="table is-fullwidth is-hoverable" style="border-collapse: collapse; margin-bottom: 0;">
      <tbody>
        <tr>
          <td class="table-nadpis table-nadpis-open" :colspan="querySW.length*4+1" @click="hideTable('plugins')" id="table-plugins">Which programs do connectors, plug-ins exist for?</td>
        </tr>
        <template v-for="index in sortedQuerySW.length">
          <tr :key="'a'+index" class="table-plugins-row">
            <!-- {{data[sortedQuerySW[index-1]].web}} -->
            <td>{{sortedQuerySW[index-1]}}</td>
            <td class="has-text-right" style="width: 75%" v-html='parsePlugins(data[sortedQuerySW[index-1]].data[64]["Společné"])'></td>
          </tr>
        </template>
      </tbody>
    </table>

    <table class="table is-fullwidth is-hoverable" style="border-collapse: collapse; margin-bottom: 0;">
      <tbody>
        <tr>
          <td class="table-nadpis table-nadpis-open" :colspan="querySW.length*4+1" @click="hideTable('price')" id="table-price">Price of licence (when buying one) per user / year</td>
        </tr>
        <template v-for="index in sortedQuerySW.length">
          <tr :key="'a'+index" class="table-price-row">
            <td>{{sortedQuerySW[index-1]}}</td>
            <td class="has-text-right" style="width: 75%" v-html='parsePrice(data[sortedQuerySW[index-1]].data[62]["Společné"])'></td>
          </tr>
        </template>
      </tbody>
    </table>

    <table class="table is-fullwidth is-hoverable" style="border-collapse: collapse;">
      <tbody>
        <tr>
          <td class="table-nadpis table-nadpis-open" :colspan="querySW.length*4+1" @click="hideTable('contact')" id="table-contact">Contacts</td>
        </tr>
        <template v-for="index in sortedQuerySW.length">
          <tr :key="'a'+index" class="table-contact-row">
            <!-- {{data[sortedQuerySW[index-1]].web}} -->
            <td>{{sortedQuerySW[index-1]}} - email</td>
            <td class="has-text-right" style="width: 50%" v-html='parseContact(data[sortedQuerySW[index-1]].kontakt)'></td>
          </tr>
          <tr :key="'b'+index" :class="index != sortedQuerySW.length ? 'table-formaty-border' : ''" class="table-contact-row">
            <!-- {{data[sortedQuerySW[index-1]].web}} -->
            <td>{{sortedQuerySW[index-1]}} - website</td>
            <td class="has-text-right" style="width: 50%" v-html='parseContact(data[sortedQuerySW[index-1]].web)'></td>
          </tr>
        </template>
      </tbody>
    </table>
    
    <p class="has-text-weight-light has-text-centered mb-4" style="color: #808080a3; margin-top: 10px;">Have you found any inaccuracies? Let us know at <a target="_blank" href="mailto:cde@czbim.org">cde@czbim.org</a></p>
  </div>


<b-modal v-model="isModalShareActive">
  <div class="card" style="padding: 25px;">
    <div class="share-card mb-4">
      <ShareNetwork v-for="network in networks" :network="network.network" :key="network.network" :style="{backgroundColor: network.color}" :url="sharing.url" :title="sharing.title">
        <i :class="network.icon"></i><span>{{ network.name }}</span>
      </ShareNetwork>
    </div>
    <div class="has-text-centered">
      <b-button @click="isModalShareActive=false" type="is-primary">Close</b-button>
    </div>
  </div>
  
</b-modal>

</div>
</template>

<script>
import otazkyJson from '@/assets/otazkyen.json'
import dataJson from '@/assets/data.json'
import otazkyInfraJson from '@/assets/otazkyInfrastrukturaen.json'

export default {
  name: 'Porovnání',
  data() {
    return { 
      software: ["Trimble Connect", "Bim.Point", "Dalux Build", "Asite", "BIMcloud", "BIMx", "Proconom", "BIMPlus", "BIM 360 Family", "ProjectWise Full version", "ProjectWise 365", "AspeHub", "CADDS3"],
      querySW: [],
      selectedSW: [],
      maxSelectedSW: 4,

      hidden: 0,

      novyradek: [1, 17, 32, 40, 49, 55],
      novyradek_nadpis: {
        1: "Basic functions, security and administration",
        17: "Work with the displayed model",
        32: "Work with drawings and documents ",
        40: "Mobile application and support of Virtual / Augmented / Mixed reality",
        49: "Localisation and support",
        55: "Licences and limitations",
      },

      otazky: null,
      otazkyInfra: null,
      data: null,

      sharing: { url: '', title: ''},
      networks: [
        { network: 'email', name: 'Email', icon: 'far fah mr-2 fa-lg fa-envelope', color: '#333333' },
        { network: 'facebook', name: 'Facebook', icon: 'fab fah mr-2 fa-lg fa-facebook-f', color: '#1877f2' },
        { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah mr-2 fa-lg fa-linkedin', color: '#007bb5' },
        { network: 'twitter', name: 'Twitter', icon: 'fab fah mr-2 fa-lg fa-twitter', color: '#1da1f2' },
      ],

      isModalSettingsActive: false,
      isModalShareActive: false
    }
  },
  computed:{
    sortedQuerySW(){
      try{
        return this.querySW.sort()
      }catch(error){
        console.log(this.querySW)
        console.log(error)
      }
    },
    sortedSW(){
      return this.software.sort()
    }
  },
  mounted(){
    if(this.$route.query.q){
      var q = this.$route.query.q

      if(Array.isArray(q) && q.length > this.maxSelectedSW) {this.$router.push("/")}
      if(!Array.isArray(q)){ q = q.split()}
      for (let sw = 0; sw < q.length; sw++) { if(!this.software.includes(q[sw])) {this.$router.push("/")}}

      this.querySW = q
      document.title = "Comparison: " + this.sortedQuerySW.join(" x ") + " | FindCDE.com"
    }
    //else{this.$router.push("/")}

    this.otazky = otazkyJson
    this.data = dataJson
    this.otazkyInfra = otazkyInfraJson
  },
  metaInfo: {
    meta: [
      { name: 'description', content: 'Comparison of popular Common Data Environment solutions.'},
      { name: 'keywords', content: 'CDE, NajdiCDE, BIM, czBIM, software, analýza CDE, srovnání CDE, porovnání CDE'}
    ]
  },
  methods:{
    print(){
      window.print();
    },
    share(){
      //this.sharing.url = encodeURIComponent(window.location.toString())
      this.sharing.url = window.location.toString()
      this.sharing.title = "Compare " + this.sortedQuerySW.join(" a ") + " on FindCDE.com"
      this.isModalShareActive = true
    },
    settings(){
      this.$router.push({ path: "/en/vyber", query: { q: this.querySW } })
    },
    toast(message, type){
      this.$buefy.toast.open({ message, type: "is-"+type })
    },
    checkVerified(bool){
      if(bool == true) {return "check-circle check-green"}
      else if(bool == "pending"){return "question-circle has-text-warning"}
      else {return "exclamation-circle has-text-danger"}
    },
    checkVerifiedSW(bool){
      if(bool == true) {return "Verified"}
      else if(bool == "pending"){return "Verification pending"}
      else {return "Not verified"}
    },
    hideInfra(){
      var from = 1
      var to = 11
      for (let index = from; index < to+1; index++) {
        var el = document.getElementById("infra"+index)
        el.classList.toggle('table-row-hidden')
        if(el.classList.contains("table-row-hidden")){ this.hidden++ }
        else this.hidden--
      }
      
      var n = document.getElementById('nadpisInfrastruktura')
      n.classList.toggle('table-nadpis-open')
      n.classList.toggle('table-nadpis-closed')
    },
    hideCategory(a, nadpis){
      var from = this.novyradek[this.novyradek.indexOf(a)]
      var to = this.novyradek[this.novyradek.indexOf(a)+1]
      if(from == 55){ to = "62"}
      for (let index = from; index < to; index++) {
        var el = document.getElementById("otazka"+index)
        el.classList.toggle('table-row-hidden')
        if(el.classList.contains("table-row-hidden")){ this.hidden++ }
        else this.hidden--
      }
      
      var n = document.getElementById(nadpis)
      n.classList.toggle('table-nadpis-open')
      n.classList.toggle('table-nadpis-closed')
    },
    hideTable(table){
      var rows = document.getElementsByClassName('table-'+table+'-row')
      for (let e = 0; e < rows.length; e++) { rows[e].classList.toggle('table-row-hidden')}
      var nadpis = document.getElementById('table-'+table)
      nadpis.classList.toggle('table-nadpis-open')
      nadpis.classList.toggle('table-nadpis-closed')
    },
    parseData(d){
      switch(d){
        case true:
          return '<i class="fas fa-check has-text-success" style="text-align: center; vertical-align: middle;"></i>'

        case false:
          return '<i class="fas fa-times has-text-danger" style="text-align: center; vertical-align: middle;"></i>'

        case "xtn":
          return '<i class="fas fa-minus has-text-grey" style="text-align: center; vertical-align: middle;"></i>'
      }
    },
    parseFormat(f){
      if(f == null){ return }

      if(f == "stejné"){
        return '<span class="has-text-grey" style="font-style: italic; font-size: 12px;">The data is identical for the web, desktop and mobile versions.</span>' //Data jsou identická u webové, desktopové i mobilní verze.
      }else if(f == "stejnéWM"){
        return '<span class="has-text-grey" style="font-style: italic; font-size: 12px;">The data is identical for the web and mobile versions.</span>'
      }else if(f == "Desítky vektorových standardů"){
        return f
      }else if(f == true || f == false){
        if(f == true){ return '<i class="fas fa-check has-text-success" style="text-align: center; vertical-align: middle;"></i>'}
        else return '<i class="fas fa-times has-text-danger" style="text-align: center; vertical-align: middle;"></i>'
      }else{
        var textArray = f.split(";");
        textArray.forEach(function (item, index) {
          if (item == "2D" || item == "3D" || item == " Kontektory" || item == " Pluginy") {
            textArray[index] = '<span class="tag is-danger">' + item + ' <i class="ml-2 fa fa-angle-right"></i> </span>'
          }else if (item == " 2D" || item == " 3D" || item == "Kontektory" || item == "Pluginy"){
            textArray[index] = '<br><span class="tag is-danger">' + item + ' <i class="ml-2 fa fa-angle-right"></i> </span>'
          }else if (item == "Neuvedeno") {
            textArray[index] = '<span class="tag is-warning"><i class="mr-2 fas fa-question"></i> ' + 'Unknown' + '</span>'
          }else {
            var newitem = ((item.replace(/\./g, "")).toLowerCase()).trim()
            textArray[index] = '<span class="tag is-primary">.'+newitem+'</span>'
          }
        })

        return textArray.join().replace(/,/g, ' ')
      }
    },
    parsePlugins(p){
      if(p == null){ return }

      if(p && p.includes('https://')){
        return `<a target="_blank" href="${p}">${p} <i class="fa fa-angle-right ml-2"></i></a>`
      }
      
      if(p == true || p == false){
        if(p == true){ return '<i class="fas fa-check has-text-success" style="text-align: center; vertical-align: middle;"></i>'}
        else return '<i class="fas fa-times has-text-danger" style="text-align: center; vertical-align: middle;"></i>'
      }else{
        var textArray = p.split(";");
        textArray.forEach(function (item, index) {
          if (item == "2D" || item == "3D" || item == " Kontektory" || item == " Pluginy") {
            textArray[index] = '<span class="tag is-danger">' + item + ' <i class="ml-2 fa fa-angle-right"></i> </span>'
          }else if (item == " 2D" || item == " 3D" || item == "Kontektory" || item == "Pluginy"){
            textArray[index] = '<br><span class="tag is-danger">' + item + ' <i class="ml-2 fa fa-angle-right"></i> </span>'
          }else if (item == "Neuvedeno") {
            textArray[index] = '<span class="tag is-warning"><i class="mr-2 fas fa-question"></i> ' + 'Unknown' + '</span>'
          }else {
            var newitem = ((item.replace(/\./g, ""))).trim()
            textArray[index] = '<span class="tag is-primary">'+newitem+'</span>'
          }
        })

        return textArray.join().replace(/,/g, ' ')
      }
    },
    parsePrice(p){
      if (p.includes("Kč") || p.includes("€")) {
        return p;
      }
      else return '<span class="tag is-warning"><i class="mr-2 fas fa-question"></i> Price on request</span>'
    },
    parseContact(c){
      //return c
      if (c.includes("@")){
        return '<a target="_blank" href="mailto:'+c+'">'+c+'</a>';
      }else if (c.includes("https://") || c.includes("www.") || c.includes("http://")){
        return '<a target="_blank" href="'+c+'" role="button">Go to '+c.replace(/(^\w+:|^)\/\//, '')+' <i class="fa fa-angle-right ml-2"></i></a>'
      }else if (c == "Neuvedeno"){
        return '<span class="tag is-warning"><i class="mr-2 fas fa-question"></i> ' + 'Unknown' + '</span>';
      }else if (c == "Dodáme"){
        return '<span class="tag is-warning"><i class="mr-2 fas fa-clock"></i> ' + 'Pending' + '</span>';  
      }else{ return c; }
    }
  }
}
</script>

<style>
.check-green{
  color: #7CFF7C;
}
</style>