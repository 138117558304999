<template>
    <div class="container">
        <div class="card subpage">
            <div class="has-text-centered" style="display: flex; justify-content: space-between; align-items: center;">
                <div class="button-back-left"><b-button tag="router-link" to="/" type="is-primary" rounded icon-left="arrow-left"></b-button></div>
                <div class="subpage-heading is-family-secondary">
                    <p class="has-text-primary">NAJDICDE.CZ</p>
                    <h1 class="is-size-3 has-text-weight-semibold">BIM metodiky</h1>
                </div>
                <div class="button-back-right" style="visibility: hidden;"><b-button tag="router-link" to="/" type="is-primary" rounded icon-left="arrow-left"></b-button></div>
            </div>

            <hr>

            <div>
                <p>Metoda BIM je budoucností českého stavebnictví v digitálním věku. Jedním z pilířů jejího úspěšného zavedení do praxe je nasazení a provoz společného datového prostředí (CDE). V něm vzniká kompletní informační model stavby neboli digitální dvojče. Bez CDE proto nelze plně těžit z výhod, které digitalizace přináší, zejména vyšší efektivitu, rychlost a menší chybovost. Sdílení informací na jednom místě přístupné odkudkoliv, je totiž v digitalizovaných procesech zásadní. Nositelem „jediné pravdy“ je právě systém společného datového prostředí.</p>
                <p class="mt-4">V rámci Česka je zodpovědnou organizací mimo jiné za vydávání metodik Česká agentura pro standardizaci. Právě její odbor Koncepce BIM vydal vybrané metodiky týkající se CDE.</p>
                <p class="mt-4">
                    <a href="https://www.koncepcebim.cz/dokumenty/spolecne-datove-prostredi-cde-zavedeni-a-vyuzivani-v-organizaci-verejneho-zadavatele/" target="_blank"> Společné datové prostředí (CDE) – zavedení a využívání v organizaci veřejného zadavatele </a> <br>
                    <a href="https://www.koncepcebim.cz/dokumenty/spolecne-datove-prostredi-cde-prehled-atributu-pro-vyber/" target="_blank"> Společné datové prostředí (CDE) – přehled atributů pro výběr </a>
                </p>
                <p class="mt-4">Nasazení systému společného datového prostředí (CDE) a jeho propojení se strukturou organizace, stylem práce a procesy je v podstatě projektem digitální transformace. Zároveň ale platí, že o využívání CDE výslovně mluví připravovaný Zákon o informačním modelu, informačním a digitálním modelování (Zákon o BIM), který by měl nabýt účinnosti k 1. červenci roku 2023. Projít touto změnou tak budou muset všichni zadavatelé veřejných zakázek s objemem vyšším než 150 milionů.</p>
                <div class="has-text-centered my-4">
                    <img :src="require('../assets/bimkoncepce.png')" alt="BIM Koncepce 2022" style="padding: 0 20px; width: 250px; margin-top: 0;">
                    <img :src="require('../assets/cas.png')" alt="Česká agentura pro standardizaci" style="padding: 0 20px; width: 250px; margin-top: 0;">
                </div>

                <div class="has-text-centered">
                    <b-button tag="router-link" to="/" type="is-primary" rounded icon-left="arrow-left">Zpět na hlavní stránku</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'BIM_Metodiky',
  metaInfo: {
    title: 'BIM Metodiky',
    titleTemplate: '%s | NajdiCDE.cz',
    meta: [
      { name: 'description', content: 'Metoda BIM je budoucností českého stavebnictví v digitálním věku. Jedním z pilířů jejího úspěšného zavedení do praxe je nasazení a provoz společného datového prostředí (CDE).'},
      { name: 'keywords', content: 'CDE, NajdiCDE, BIM, czBIM, software, analýza CDE, srovnání CDE, BIM metodiky'}
    ]
  }
}
</script>

<style scoped>

</style>
