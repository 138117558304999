<template>
    <div class="container">
        <div class="card subpage">
            <div class="has-text-centered" style="display: flex; justify-content: space-between; align-items: center;">
                <div class="button-back-left"><b-button tag="router-link" to="/" type="is-primary" rounded icon-left="arrow-left"></b-button></div>
                <div class="subpage-heading is-family-secondary">
                    <p class="has-text-primary">NAJDICDE.CZ</p>
                    <h1 class="is-size-3 has-text-weight-semibold">Co je CDE?</h1>
                </div>
                <div class="button-back-right" style="visibility: hidden;"><b-button tag="router-link" to="/" type="is-primary" rounded icon-left="arrow-left"></b-button></div>
            </div>

            <hr>

            <div>
                <p class="has-text-weight-semibold">O CDE</p>
                <p>Kvalitně řídit projekty pomocí telefonu, e-mailu a excelu v dnešní době již nelze. Vznikly proto DMS nástroje (Document Management System) a řešení CDE (Common Data Environment). Zatímco se DMS produkty primárně orientují na práci s dokumenty, CDE aplikace umožňují pracovat i s informačními BIM modely, a to přímo v samotném prostředí CDE bez nutnosti pořizování a instalace dalších softwarových nástrojů. Právě tato vlastnost posouvá CDE na jinou, mnohem efektivnější úroveň sdílení, komunikace a řízení BIM projektů. Jeden obrázek je víc než 1000 slov a jeden model víc než 1000 obrázků.</p>
                <p class="note">Společné datové prostředí je centrální úložiště informací, kam mohou přistupovat všichni účastníci projektu a dle pokynů uvedených v normě ISO 19650 koordinovat spolupráci na projektu s členy dodavatelského řetězce – různými profesemi.</p>
                <p class="has-text-weight-semibold">Co je CDE a jeho základní vlastnosti</p>
                <ul>
                    <li>Prostředí pro komunikaci všech osob podílejících se na projektu.</li>
                    <li>Hlavní nástroj pro implementaci BIM principů.</li>
                    <li>Sdružuje výstupy jednotlivých expertních systémů.</li>
                    <li>Není určeno pro jednotlivé expertní agendy. Nenahrazuje je, naopak je spojuje.</li>
                    <li>Klíčová je snadná dostupnost pro všechny partnery a jednoduché ovládání.</li>
                </ul>
                <p class="has-text-weight-semibold">Kdo by měl CDE vybírat?</p>
                <p>Výběr CDE by měl ideálně provádět manažer konkrétního projektu. V odborných publikacích a metodikách se uvádí, že CDE poskytuje (tedy i vybírá) zadavatel (investor). Často se díky tomu setkáváme s názorem, že nemá smysl, aby se touto problematikou zabýval projektant nebo zhotovitel. Je tedy pravděpodobné, že v práci pro investory bude projekce i realizace pracovat s různými řešeními CDE na různých projektech. To, že by se v celé ČR používalo jen jedno řešení CDE, je určitě snem nejednoho softwarového dodavatele. Ale svět si je dobře vědom nebezpečí tzv. vendor lock-in (proprietární uzamčení zákazníka v rámci služeb konkrétního dodavatele) a sekundárně i technologické degenerace v prostředí bez konkurence. I proto vznikají iniciativy typu openCDE a již roky se preferuje openBIM spolupráce založená na otevřených standardech (IFC). Tyto zkušenosti jsou přenositelné i do našeho prostředí.</p>
                <p class="note">Díky „svému“ CDE máte v datech a informačních BIM modelech pořádek</p>
                <p>Pokud zadavatel určí CDE, je logické splnit jeho požadavky, ale CDE, která preferuje zadavatel často nejsou vhodná pro projekční ani realizační firmu. I z tohoto důvodu je vhodné mít „vlastní“ CDE optimální pro danou činnost. Některá CDE jsou v práci s informačními BIM modely tak daleko, že díky nim se již realizují bezpapírové, resp. bezvýkresové stavby.</p>
                <p class="has-text-weight-semibold mt-4">Základní rady k výběru CDE</p>
                <p>Buďte obezřetní k radám poskytovatelů řešeních CDE. Stejně jako rodiče své dětí, oni své produkty ne vždy a zcela objektivně hodnotí. Buďte zvídaví, ptejte se, požadujte živou ukázku nikoli video, pokuste se sehnat reference, ptejte se kolegů, kterým důvěřujete, chtějte vidět Vámi požadované funkcionality naživo, minimálně na vzorcích vašich dat a hlavně, čerpejte data z naší webové prezentace!</p>
                <p class="mt-2">Pozor na komplikovaná řešení s dlouhou implementací. Myslete na to, že stěžejní je obecné přijetí CDE v rámci projektu, a tedy i jednotlivých organizací, které se ho účastní. Můžete mít „teoreticky“ fantastické řešení, které ale nikdo nebude používat.</p>
                <p class="mt-2">Pokud Vám ve výběru CDE pomůže náš projekt budeme moc rádi.</p>
                <p class="has-text-weight-semibold mt-4">Závěr</p>
                <p>Nedávno jsme si všimli článku s nadpisem v následujícím duchu „Teprve s BIM je architekt skutečným tvůrcem stavby“. Podle nás stavby tvoří tým lidí jejichž cílem není BIM, ale realizovaná stavba v kvalitě umožňující její dlouhodobé užívání a efektivní správu. Mnoho osob z tohoto týmu má daleko větší odpovědnost než architekti (např. statici) a řada osob realizuje často stavby ve vysoce rizikovém prostředí (např. stavbaři). Všem těmto účastníkům může pomoci v jejich namáhavé práci správně zvolené řešení CDE jako součást BIM procesů, a to s ohledem na povahu jejich činností a konkrétní projekt.</p>
                <p class="has-text-weight-semibold mt-4">Řešitelé projektu</p>
                <p>Martin Maťašovský, Pavel Vlasák, Ivo Kohoušek, Bořek Čerbák a tým Odborné rady pro BIM, z.s. <a href="https://czbim.org" target="_blank">www.czbim.org</a>, <a href="mailto:cde@czbim.org" target="_blank">cde@czbim.org</a>
                    <br>
                    <a href="https://czbim.org" target="_blank"><img :src="require('@/assets/bim-logo.svg')" style="height: 70px;" class="my-4 mr-5"></a><img :src="require('@/assets/buildingsmart.png')" style="height: 70px;" class="my-4"><br>
                    <b>Odborná rada pro BIM z.s.</b><br>
                    Budova Fakulty stavební ČVUT v Praze, Thákurova 7, Praha 6, 166 29<br>
                    <a href="http://czbim.org">www.czbim.org</a><br>
                    <a href="mailto:cde@czbim.org">cde@czbim.org</a> | <a href="mailto:info@czbim.org">info@czbim.org</a>
                </p>

                <div class="has-text-centered mt-4">
                    <b-button tag="router-link" to="/" type="is-primary" rounded icon-left="arrow-left">Zpět na hlavní stránku</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CDE',
    metaInfo: {
      title: 'Co je CDE?',
      titleTemplate: '%s | NajdiCDE.cz',
      meta: [
          { name: 'description', content: 'Zatímco se DMS produkty primárně orientují na práci s dokumenty, CDE aplikace umožňují pracovat i s informačními BIM modely, a to přímo v samotném prostředí CDE bez nutnosti pořizování a instalace dalších softwarových nástrojů.'},
          { name: 'keywords', content: 'CDE, NajdiCDE, BIM, czBIM, software, analýza CDE, srovnání CDE, Co je CDE'}
      ]
    }
}
</script>

<style scoped>

</style>