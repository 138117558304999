import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Buefy from 'buefy'
Vue.use(Buefy)

import VuePageTransition from 'vue-page-transition'
Vue.use(VuePageTransition)

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import Clipboard from 'v-clipboard'
Vue.use(Clipboard)

import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

Sentry.init({
  Vue,
  dsn: "https://0c3a48851f88428786e58bd15e577de7@o576881.ingest.sentry.io/5731011",
  integrations: [new Integrations.BrowserTracing()],
  // beforeSend(event, hint) {
  //   if (event.exception) {
  //     Sentry.showReportDialog({ eventId: event.event_id });
  //   }
  //   return event;
  // },
  tracesSampleRate: 1.0,
});

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
