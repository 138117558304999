<template>
<div class="vertical-center-content animated-bg" style="justify-content: center;">
  <div class="card vyber-card" style="padding: 50px;">
    <h2 class="is-size-3 has-text-weight-medium has-text-centered is-family-secondary">Choose a CDE solution</h2>
    <p class="has-text-grey has-text-centered mb-6">You can choose max. {{maxSelectedSW}} CDE solutions at once.</p>
    
    <div class="mb-6 vyber-grid">
      <!-- <b-switch v-for="index in software.length" :key="index">{{software[index-1]}}</b-switch> -->
      <b-checkbox v-for="index in software.length" :key="index" v-model="selectedSW" :native-value="sortedSW[index-1]">{{sortedSW[index-1]}}</b-checkbox>
    </div>
    
    <div class="has-text-centered">
    <b-button type="is-primary" class="mb-2" rounded @click="cont()">Show features</b-button>
    </div>

    <div class="has-text-centered" v-if="!hideButton">
      <b-button tag="router-link" to="/en/" type="is-light" rounded icon-left="arrow-left">Back</b-button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Vyber',
  data() {
    return { 
      software: ["Trimble Connect", "Bim.Point", "Dalux Build", "Asite", "BIMcloud", "BIMx", "Proconom", "BIMPlus", "BIM 360 Family", "ProjectWise Full version", "ProjectWise 365", "AspeHub", "CADDS3"],
      selectedSW: [],
      maxSelectedSW: 4,
      hideButton: false,
    }
  },
  computed:{
    sortedSW(){
      return this.software.sort()
    }
  },
  mounted() {
    if(this.$route.query.q){this.selectedSW = this.$route.query.q; this.hideButton = true}
  },
  metaInfo: {
    title: 'Choose a CDE solution',
    titleTemplate: '%s | NajdiCDE.cz',
    meta: [
      { name: 'description', content: 'Please choose a Common Data Environment solution. You can choose upto 4 solutions at once. '},
      { name: 'keywords', content: 'CDE, NajdiCDE, BIM, czBIM, software, analýza CDE, srovnání CDE, výběr CDE'}
    ]
  },
  methods:{
    cont(){
      if(this.selectedSW.length > this.maxSelectedSW){
        this.toast(`You can choose max. ${this.maxSelectedSW} CDE solutions at once.`, "danger")
      }else if(this.selectedSW.length < 1){
        this.toast("Please choose at least 1 CDE solution.", "danger")
      }else{
        this.$router.push({ path: "/en/porovnani", query: { q: this.selectedSW } })
      }
    },
    toast(message, type){
      this.$buefy.toast.open({ message, type: "is-"+type })
    }
  }
}
</script>