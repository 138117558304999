<template>
    <div class="container">
        <div class="card subpage">
            <div class="has-text-centered" style="display: flex; justify-content: space-between; align-items: center;">
                <div class="button-back-left"><b-button tag="router-link" to="/" type="is-primary" rounded icon-left="arrow-left"></b-button></div>
                <div class="subpage-heading is-family-secondary">
                    <p class="has-text-primary">NAJDICDE.CZ</p>
                    <h1 class="is-size-3 has-text-weight-semibold">O projektu CDE v rámci Odborné rady pro BIM</h1>
                </div>
                <div class="button-back-right" style="visibility: hidden;"><b-button tag="router-link" to="/" type="is-primary" rounded icon-left="arrow-left"></b-button></div>
            </div>

            <hr>

            <div>
                <p class="has-text-weight-semibold">CDE - cíl projektu</p>
                <p>Cílem projektu CDE je shromáždit informace o jednotlivých řešeních CDE a tyto přehledně na jednom místě zprostředkovat široké odborné veřejnosti prostřednictvím webové aplikace, kde základ tvoří databáze jednotlivých CDE programů s důležitými a nejvíce požadovanými vlastnostmi pro různé typy činností, tj. od projekce, přes realizaci až po správu nemovitostí.</p>
                
                <p class="has-text-weight-semibold mt-4">CDE - hodnocení a verifikace vlastností jednotlivých řešení</p>
                <p>Cílem projektu není jednotlivá řešení CDE jakkoli subjektivně hodnotit ani doporučovat. Cílem je uživateli nabídnout kompletní přehled dostupných CDE aplikací na českém i slovenském trhu a jejich klíčových vlastností – funkcionalit. Zobrazovaná data (vlastnosti) obdržená od jednotlivých vlastníků nebo dodavatelů konkrétních řešení CDE jsou postupně ověřována (verifikována), a to jak namátkově, tak i uživateli konkrétních CDE.</p>
                
                <p class="has-text-weight-semibold mt-4">CDE - aktualizace uveřejněných dat</p>
                <p>Data v databázi aplikace budou dle potřeby aktualizována a to minimálně jednou ročně, nebo kdykoli, kdy nastane změna určité vlastnosti. K dispozici je emailová adresa <a href="mailto:cde@czbim.org" target="_blank">cde@czbim.org</a>, kam může kdokoli poslat podmět týkající se určité nepřesnosti v databázi na základě uživatelských zkušeností. Jsme si vědomi, že problematika řešení CDE je živou množinou technických i procesních dat, které s neustále vyvíjí. </p>
                
                <p class="has-text-weight-semibold mt-4">CDE - základní podmínky účasti v projektu</p>
                <p>Proto, abychom mohli uživatelům poskytnout ty nejpřesnější a použitelné informace, a zároveň se eliminovala řešení, která by se mohla tvářit jako CDE, ač nesplňují potřebné požadavky, byly stanoveny základní podmínky pro možnost zveřejnění řešení CDE na webu najdiCDE.cz:</p>
                <ul>
                    <li>CDE disponuje základními funkcemi: nahrání, sdílení, stažení dat</li>
                    <li>CDE umožňuje zadávat úkoly</li>
                    <li>CDE podporuje práci se zobrazeným modelem ve formátu *.IFC (IFC4x)</li>
                    <li>CDE má zastoupení a podporu v ČR</li>
                </ul>

                <p class="has-text-weight-semibold mt-4">CDE - oslovené společnosti</p>
                <p>Aktuálně byli oslovení všichni známí dodavatelé v rámci České republiky, kteří splňují výše uvedené podmínky. Jsme ale otevřeni všem a připraveni kdykoli přijmout další dostupné řešení, avšak minimálně za splnění výše stanovených podmínek účasti v projektu.</p>

                <p class="has-text-weight-semibold mt-4">Řešitelé projektu</p>
                <p>Martin Maťašovský, Pavel Vlasák, Ivo Kohoušek, Bořek Čerbák a tým Odborné rady pro BIM, z.s. <a href="https://czbim.org" target="_blank">www.czbim.org</a>, <a href="mailto:cde@czbim.org" target="_blank">cde@czbim.org</a>
                    <br>
                    <a href="https://czbim.org" target="_blank"><img :src="require('@/assets/bim-logo.svg')" style="height: 70px;" class="my-4 mr-5"></a><img :src="require('@/assets/buildingsmart.png')" style="height: 70px;" class="my-4"><br>
                    <b>Odborná rada pro BIM z.s.</b><br>
                    Budova Fakulty stavební ČVUT v Praze, Thákurova 7, Praha 6, 166 29<br>
                    <a href="http://czbim.org">www.czbim.org</a><br>
                    <a href="mailto:cde@czbim.org">cde@czbim.org</a> | <a href="mailto:info@czbim.org">info@czbim.org</a>
                </p>

                <div class="has-text-centered mt-4">
                    <b-button tag="router-link" to="/" type="is-primary" rounded icon-left="arrow-left">Zpět na hlavní stránku</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Projekt',
  metaInfo: {
    title: 'Projekt',
    titleTemplate: '%s | NajdiCDE.cz',
    meta: [
      { name: 'description', content: 'Cílem projektu CDE je shromáždit informace o jednotlivých řešeních CDE a tyto přehledně na jednom místě zprostředkovat široké odborné veřejnosti.'},
      { name: 'keywords', content: 'CDE, NajdiCDE, BIM, czBIM, software, analýza CDE, srovnání CDE, O projektu CDE v rámci Odborné rady pro BIM'}
    ]
  }
}
</script>

<style scoped>

</style>