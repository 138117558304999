<template>
<div class="vertical-center-content animated-bg" style="justify-content: center;">
  <div class="card vyber-card" style="padding: 50px;">
    <h2 class="is-size-3 has-text-weight-medium has-text-centered is-family-secondary">Vyberte řešení CDE</h2>
    <p class="has-text-grey has-text-centered mb-6">Současně lze vybrat max {{maxSelectedSW}} řešení CDE.</p>

    <div class="mb-6 vyber-grid">
      <!-- <b-switch v-for="index in software.length" :key="index">{{software[index-1]}}</b-switch> -->
      <b-checkbox v-for="index in software.length" :key="index" v-model="selectedSW" :native-value="sortedSW[index-1]">{{sortedSW[index-1]}}</b-checkbox>
    </div>

    <div class="has-text-centered">
    <b-button type="is-primary" class="mb-2" rounded @click="cont()">Zobrazit vlastnosti</b-button>
    </div>

    <div class="has-text-centered" v-if="!hideButton">
      <b-button tag="router-link" to="/" type="is-light" rounded icon-left="arrow-left">Zpět</b-button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Vyber',
  data() {
    return {
      // software: ["Trimble Connect", "Bim.Point", "Dalux Build", "Asite", "Proconom", "ProjectWise 2023", "AspeHub", "CADDS3", "Fieldwire"],
      software: ["Trimble Connect", "Bim.Point", "Asite", "Proconom", "ProjectWise 2023", "AspeHub", "CADDS3", "Fieldwire"],
      selectedSW: [],
      maxSelectedSW: 4,
      hideButton: false,
    }
  },
  computed:{
    sortedSW(){
      return this.software.sort()
    }
  },
  mounted() {
    if(this.$route.query.q){this.selectedSW = this.$route.query.q; this.hideButton = true}
  },
  metaInfo: {
    title: 'Výběr CDE',
    titleTemplate: '%s | NajdiCDE.cz',
    meta: [
      { name: 'description', content: 'Vyberte prosím řešení Common Data Environment k porovnání. Maximálně lze vybrat 4 řešení najednou. '},
      { name: 'keywords', content: 'CDE, NajdiCDE, BIM, czBIM, software, analýza CDE, srovnání CDE, výběr CDE'}
    ]
  },
  methods:{
    cont(){
      if(this.selectedSW.length > this.maxSelectedSW){
        this.toast(`Současně lze vybrat max ${this.maxSelectedSW} řešení CDE.`, "danger")
      }else if(this.selectedSW.length < 1){
        this.toast("Vyberte alespoň 1 řešení CDE.", "danger")
      }else{
        this.$router.push({ path: "/porovnani", query: { q: this.selectedSW } })
      }
    },
    toast(message, type){
      this.$buefy.toast.open({ message, type: "is-"+type })
    }
  }
}
</script>
