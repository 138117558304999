import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Vyber from '../views/Vyber.vue'
import Porovnani from '../views/Porovnani.vue'

import CDE from '../views/CDE.vue'
import Projekt from '../views/Projekt.vue'
import Metodiky from '../views/Metodiky.vue'

import Stats from '../views/Stats.vue'
import Gen from '../views/Gen.vue'

import HomeEN from '../views/EN/Home.vue'
import CDEEN from '../views/EN/CDE.vue'
import MetodikyEN from '../views/EN/Metodiky.vue'
import ProjektEN from '../views/EN/Projekt.vue'
import VyberEN from '../views/EN/Vyber.vue'
import PorovnaniEN from '../views/EN/Porovnani.vue'
import StatistikyEN from '../views/EN/Stats.vue'


import NotFoundComponent from '../views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/gen',
    component: Gen
  },
  {
    path: '/vyber',
    name: 'Vyber',
    component: Vyber
  },
  {
    path: '/porovnani',
    name: 'Porovnání',
    component: Porovnani
  },
  {
    path: '/CDE',
    name: 'Co je CDE?',
    component: CDE
  },
  {
    path: '/projekt',
    name: 'O projektu',
    component: Projekt
  },
  {
    path: '/metodiky',
    name: 'BIM Metodiky',
    component: Metodiky
  },
  {
    path: '/statistiky',
    name: 'Statistika',
    component: Stats
  },
  {
    path: '/en/',
    name: 'HomeEN',
    component: HomeEN
  },
  {
    path: '/en/CDE',
    name: 'About CDE',
    component: CDEEN
  },
  {
    path: '/en/metodiky',
    name: 'BIM Methods',
    component: MetodikyEN
  },
  {
    path: '/en/projekt',
    name: 'About the project',
    component: ProjektEN
  },
  {
    path: '/en/vyber',
    name: 'Choose a CDN',
    component: VyberEN
  },
  {
    path: '/en/porovnani',
    name: 'Comparison',
    component: PorovnaniEN
  },
  {
    path: '/en/statistiky',
    name: 'Statistics',
    component: StatistikyEN
  },
  
  { path: '*', component: NotFoundComponent }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
