<template>
    <div class="container">
        <div class="card subpage">
            <div class="has-text-centered" style="display: flex; justify-content: space-between; align-items: center;">
                <div class="button-back-left"><b-button tag="router-link" to="/en/" type="is-primary" rounded icon-left="arrow-left"></b-button></div>
                <div class="subpage-heading is-family-secondary">
                    <p class="has-text-primary">FINDCDE.COM</p>
                    <h1 class="is-size-3 has-text-weight-semibold">What is CDE?</h1>
                </div>
                <div class="button-back-right" style="visibility: hidden;"><b-button tag="router-link" to="/en/" type="is-primary" rounded icon-left="arrow-left"></b-button></div>
            </div>

            <hr>

            <div>
                <p class="has-text-weight-semibold">ABOUT CDE</p>
                <p>Quality project management using a telephone, e-mail and Excel is no longer an option. This is why DMS (Document Management System) tools and CDE (Common Data Environment) solutions have been created. While DMS products focus primarily on working with documents, CDE applications also enable working with BIM information models directly in the CDE environment, without having to purchase and install other software tools. This feature moves CDE to another, far more efficient level of sharing, communication and management of BIM projects. A picture is worth a thousand words and one model is worth more than a thousand pictures.</p>
                <p class="note">The common data environment is a central information repository, which all the project participants can access and, based on the instructions set forth in ISO 19650, coordinate cooperation on the project with members of the supply chain – various professions.</p>
                <p class="has-text-weight-semibold">Definition of CDE and its basic features</p>
                <ul>
                    <li>An environment for communication between all the parties involved in a project.</li>
                    <li>Key tool for implementing BIM principles.</li>
                    <li>Brings together the inputs of individual expert systems.</li>
                    <li>Not designed for individual expert agendas. It does not replace them, but instead unifies them.</li>
                    <li>A key feature is easily availability for all the users and intuitive command.</li>
                </ul>
                <p class="has-text-weight-semibold">Who should choose the CDE?</p>
                <p>VThe CDE should ideally be chosen by the manager of the given project. Specialised publications and methodologies state that the CDE is provided (and therefore chosen) by the investor. As a result, we often hear the opinion that it is pointless for the project designer or contractor to deal with this issue. Hence, it is likely that in working for investors, the project designers and contractors will be using various CDE solutions for various projects. It is doubtless every software supplier’s dream that only one CDE solution be used across the Czech Republic, but the world is well aware of the risks of vendor lock-in, and the secondary technological degeneration of an environment without healthy competition. This is why initiatives like openCDE are created, and openBIM cooperation based on open standards (IFC) has been preferred for years. This experience can be applied to our environment too.</p>
                <p class="note">Thanks to “your” CDE, you can keep your data and BIM information models organised.</p>
                <p>If the investor specifies the CDE, it is logical to comply with its requirements, but the CDE preferred by the investor is often inappropriate for the project design and implementation company. This is why it’s advisable to have your “own” CDE that is optimal for the given activity. Some CDE have gone so far in working with BIM information models that they are able to construct paper-free, respectively blueprint-free buildings. </p>
                <p class="has-text-weight-semibold mt-4">Basic advice when choosing a CDE</p>
                <p>Be wary of the advice of CDE solution providers. Like parents with respect to their children, even they don’t always assess their products quite objectively. Be inquisitive, ask, request a live presentation rather than a video, try to find references, ask colleagues you trust, demand to see the required functionalities live, minimally on samples of your data, and above all use the data from our web presentation!</p>
                <p class="mt-2">Watch out for complicated solutions with long implementation times. Keep in mind that the core issue is the general adoption of the CDE within the project, meaning by the individual organisations participating in it. You can have a “theoretically” fantastic solution, but nobody will use it.</p>
                <p class="mt-2">We will be delighted if our project helps you to choose a CDE.</p>
                <p class="has-text-weight-semibold mt-4">Conclusion</p>
                <p>We recently noticed an article headlined as follows: “Only with BIM does an architect truly become the creator of a building.” In our view, buildings are created by a team of people whose aim is not BIM, but a building completed to such a degree of quality that permits long-term use and efficient management. Many people on this team have far more responsibility that the architects (e.g. structural engineers), and many often construct the building in a high-risk environment (e.g. builders). A correctly chosen CDE solution as part of the BIM process can help all of these participants in their demanding jobs, depending on the nature of their activity and specific project.</p>
                <p class="has-text-weight-semibold mt-4">Project solvers</p>
                <p>Martin Maťašovský, Pavel Vlasák, Ivo Kohoušek, Bořek Čerbák a tým Odborné rady pro BIM, z.s. <a href="https://czbim.org" target="_blank">www.czbim.org</a>, <a href="mailto:cde@czbim.org" target="_blank">cde@czbim.org</a>
                    <br>
                    <a href="https://czbim.org" target="_blank"><img :src="require('@/assets/bim-logo.svg')" style="height: 70px;" class="my-4 mr-5"></a><img :src="require('@/assets/buildingsmart.png')" style="height: 70px;" class="my-4"><br>
                    <b>Odborná rada pro BIM z.s.</b><br>
                    Budova Fakulty stavební ČVUT v Praze, Thákurova 7, Praha 6, 166 29<br>
                    <a href="http://czbim.org">www.czbim.org</a><br>
                    <a href="mailto:cde@czbim.org">cde@czbim.org</a> | <a href="mailto:info@czbim.org">info@czbim.org</a>
                </p>

                <div class="has-text-centered mt-4">
                    <b-button tag="router-link" to="/en/" type="is-primary" rounded icon-left="arrow-left">Go back</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CDE',
    metaInfo: {
      title: 'What is CDE?',
      titleTemplate: '%s | NajdiCDE.cz',
      meta: [
          { name: 'description', content: 'While DMS products focus primarily on working with documents, CDE applications also enable working with BIM information models directly in the CDE environment, without having to purchase and install other software tools.'},
          { name: 'keywords', content: 'CDE, NajdiCDE, BIM, czBIM, software, analýza CDE, srovnání CDE, Co je CDE'}
      ]
    }
}
</script>

<style scoped>

</style>