<template>
<div class="vertical-center-content">
  <div class="container cde-header">
    <div class="cde-header-left">
      <a href="https://www.czbim.org/" target="_blank"><img :src="require('@/assets/bim-logo.svg')" alt="Logo" class="cde-header-logo mb-4 mr-6" style="height: 50px"></a>
      <img :src="require('@/assets/buildingsmart.png')" alt="BuildingSmart Czech Republic" class="cde-header-logo mb-4" style="height: 50px">
      <h1 class="is-size-2 mb-4 has-text-weight-medium is-family-secondary">Making sense of <span class="has-text-primary">CDE solutions</span></h1>
      <p class="mb-5">We have collected information about the individual CDE solutions, which we have loaded into our database and verified. In doing so, we referred to the most important and highly-sought features for various types of activities, from projection through implementation to facility management.</p>

      <div class="columns">
        <div class="column" style="padding-bottom: 0"><b-button expanded rounded type="is-light" tag="router-link" to="/en/CDE">What is CDE?</b-button></div>
        <div class="column" style="padding-bottom: 0"><b-button expanded rounded type="is-light" tag="router-link" to="/en/projekt">About the project</b-button></div>
        <div class="column" style="padding-bottom: 0"><b-button expanded rounded type="is-light" tag="router-link" to="/en/metodiky">Available methods</b-button></div>
      </div>
      <div class="columns">
        <div class="column"><b-button expanded rounded type="is-primary" icon-right="arrow-right" tag="router-link" to="/en/vyber">Find a CDE</b-button></div>
      </div>
    </div>

    <div class="cde-header-right">
      <img :src="require('@/assets/cdeen.png')" alt="Najdi CDE">
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Home',
  metaInfo: {
    title: 'Making sense of CDE solutions',
    titleTemplate: '%s | NajdiCDE.cz',
    meta: [
      { name: 'description', content: 'We have collected information about the individual CDE solutions, which we have loaded into our database and verified.'},
      { name: 'keywords', content: 'CDE, NajdiCDE, BIM, czBIM, software, analýza CDE, srovnání CDE'}
    ]
  }
}
</script>