<template>
    <div class="container">
        <div class="card subpage">
            <div class="has-text-centered" style="display: flex; justify-content: space-between; align-items: center;">
                <div class="button-back-left"><b-button tag="router-link" to="/en/" type="is-primary" rounded icon-left="arrow-left"></b-button></div>
                <div class="subpage-heading is-family-secondary">
                    <p class="has-text-primary">FINDCDE.COM</p>
                    <h1 class="is-size-3 has-text-weight-semibold">About the CDE project within the Czech BIM Council</h1>
                </div>
                <div class="button-back-right" style="visibility: hidden;"><b-button tag="router-link" to="/en/" type="is-primary" rounded icon-left="arrow-left"></b-button></div>
            </div>

            <hr>

            <div>
                <p class="has-text-weight-semibold">CDE – Project objectives</p>
                <p>The aim of the CDE project is to gather information about the individual CDE solutions and make these available in an intuitive manner to the broad expert public, all in one place through a web application. The basis of this application will be a database of individual CDE programs with the most important and requested features for various types of activities, from project design through construction to facility management. </p>
                
                <p class="has-text-weight-semibold mt-4">CDE – Evaluation and verification of individual solution features</p>
                <p>The aim of the project is not to evaluate or recommend the individual CDE solutions in any subjective manner. The objective is to offer users a comprehensive overview of available CDE applications on the Czech and Slovak market and their key features – functionalities. The presented data (features) obtained from the individual owners or suppliers of specific CDE solutions will be gradually verified, both randomly and by users of the particular CDE.</p>
                
                <p class="has-text-weight-semibold mt-4">CDE – Updates of published data</p>
                <p>The data in the application database will be updated as needed, minimally once per year or whenever a change in features occurs. An e-mail address <a href="mailto:cde@czbim.org" target="_blank">cde@czbim.org</a> is available for anybody to send suggestions regarding inaccuracies in the database founded on user experience. We are aware that the issue of CDE solutions is a living set of technical and process data, which evolve continuously. </p>
                
                <p class="has-text-weight-semibold mt-4">CDE – Basic conditions for participation in the project</p>
                <p>In order to provide users with the most accurate and valuable information while eliminating solutions that may appear to be CDE, although they do not meet the necessary requirements, some basic conditions have been defined to allow publication of the CDE on the najdiCDE.cz website:</p>
                <ul>
                    <li>the CDE has the basic functions: loading, sharing, downloading of data</li>
                    <li>the CDE enables the task assignment</li>
                    <li>the CDE supports work with the displayed model in *.IFC (IFC4x) format</li>
                    <li>the CDE has representation and support in CZ</li>
                </ul>

                <p class="has-text-weight-semibold mt-4">CDE – Contacted companies </p>
                <p>All the known suppliers in the Czech Republic who meet all the aforementioned conditions have already been contacted. However, we are open to everybody and ready to accept other available solutions, provided they fulfil minimally the aforementioned conditions for participation in the project.</p>

                <p class="has-text-weight-semibold mt-4">Project solvers</p>
                <p>Martin Maťašovský, Pavel Vlasák, Ivo Kohoušek, Bořek Čerbák a tým Odborné rady pro BIM, z.s. <a href="https://czbim.org" target="_blank">www.czbim.org</a>, <a href="mailto:cde@czbim.org" target="_blank">cde@czbim.org</a>
                    <br>
                    <a href="https://czbim.org" target="_blank"><img :src="require('@/assets/bim-logo.svg')" style="height: 70px;" class="my-4 mr-5"></a><img :src="require('@/assets/buildingsmart.png')" style="height: 70px;" class="my-4"><br>
                    <b>Odborná rada pro BIM z.s.</b><br>
                    Budova Fakulty stavební ČVUT v Praze, Thákurova 7, Praha 6, 166 29<br>
                    <a href="http://czbim.org">www.czbim.org</a><br>
                    <a href="mailto:cde@czbim.org">cde@czbim.org</a> | <a href="mailto:info@czbim.org">info@czbim.org</a>
                </p>

                <div class="has-text-centered mt-4">
                    <b-button tag="router-link" to="/en/" type="is-primary" rounded icon-left="arrow-left">Go back</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Projekt',
  metaInfo: {
    title: 'About the project',
    titleTemplate: '%s | NajdiCDE.cz',
    meta: [
      { name: 'description', content: 'The aim of the CDE project is to gather information about the individual CDE solutions and make these available in an intuitive manner to the broad expert public.'},
      { name: 'keywords', content: 'CDE, NajdiCDE, BIM, czBIM, software, analýza CDE, srovnání CDE, O projektu CDE v rámci Odborné rady pro BIM'}
    ]
  }
}
</script>

<style scoped>

</style>