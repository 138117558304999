<template>
    <div class="container">
        <div class="card subpage">
            <div class="has-text-centered" style="display: flex; justify-content: space-between; align-items: center;">
                <div class="button-back-left"><b-button tag="router-link" to="/en/" type="is-primary" rounded icon-left="arrow-left"></b-button></div>
                <div class="subpage-heading is-family-secondary">
                    <p class="has-text-primary">NAJDICDE.CZ</p>
                    <h1 class="is-size-3 has-text-weight-semibold">Statistics</h1>
                </div>
                <div class="button-back-right" style="visibility: hidden;"><b-button tag="router-link" to="/en/" type="is-primary" rounded icon-left="arrow-left"></b-button></div>
            </div>

            <hr>
            <div v-if="!loading && eur">
                <h1 class="is-size-4 is-family-secondary">Amount of <i class="fas fa-check has-text-success" style="text-align: center; vertical-align: middle;"></i> and <i class="fas fa-times has-text-danger" style="text-align: center; vertical-align: middle;"></i> in the table</h1>
                <b-table :data="sw" hoverable narrowed default-sort-direction="desc" default-sort="ano">
                    <b-table-column field="cde" width="50%" label="Solution" sortable v-slot="props">{{ props.row.cde }}</b-table-column>
                    <b-table-column field="ano" width="25%" label="Yes" sortable v-slot="props">{{ props.row.ano }}</b-table-column>
                    <b-table-column field="ne" width="25%" label="No" sortable v-slot="props">{{ props.row.ne }}</b-table-column>
                </b-table>

                <h1 class="is-size-4 is-family-secondary mt-6">Price per license per year</h1>
                <p class="is-size-6 has-color-grey">Some prices were converted from CZK to EUR according to the current rate - <a href="https://www.ecb.europa.eu/stats/policy_and_exchange_rates/euro_reference_exchange_rates/html/index.en.html" target="_blank">1€ = {{eur}} Kč</a><br>
                Solutions that do not have a price publicly available are missing ({{chybí.join(", ")}})</p>
                <b-table :data="cena" hoverable narrowed default-sort-direction="asc" default-sort="cena">
                    <b-table-column field="cde" width="50%" label="Solution" sortable v-slot="props">{{ props.row.cde }}</b-table-column>
                    <b-table-column field="cena" width="50%" label="Price" sortable :custom-sort="sortMoney" v-slot="props">{{ props.row.cena }}</b-table-column>
                </b-table>
                <p class="is-size-6 has-color-grey mt-4"></p>
            </div>
            <div v-else>
                <b-loading v-model="loading" :can-cancel="true"></b-loading>
            </div>
        </div>
    </div>
</template>

<script>
import dataJson from '@/assets/data.json'

export default {
    name: 'Stats',
    data () {
        return {
            dataSource: null,
            loading: true,
            eur: null,
            software: ["Trimble Connect", "BIM 360 Family", "BIMx", "BIMcloud", "Dalux Build", "Bim.Point", "Asite", "Proconom", "BIMPlus", "ProjectWise Full version", "ProjectWise 365", "AspeHub", "CADDS3"],
            sw: [],
            cena: [],
            chybí: []
        }
    },
    async mounted() {
        document.title = "Statistics | NajdiCDE.cz"
        this.dataSource = dataJson

        try{
            var _this = this
            const xhr = new XMLHttpRequest()
            xhr.open('GET', 'https://cors.bridged.cc/https://www.ecb.europa.eu/stats/eurofxref/eurofxref-daily.xml', true, null, null)
            xhr.setRequestHeader('x-requested-With', 'XMLHttpRequest')
            xhr.setRequestHeader('Accept', 'application/json')
            xhr.send()

            xhr.onload = function () {
              if (xhr.readyState === xhr.DONE) {
                if (xhr.status === 200) {
                  const parser = new DOMParser()
                  const dom = parser.parseFromString(xhr.response, "application/xml")
                  try{
                    _this.eur = dom.querySelectorAll('[currency="CZK"]')[0].attributes.rate.nodeValue
                    _this.getData()
                    _this.getPrice()
                    _this.loading = false
                  }catch(e){
                    _this.eur = 25
                    _this.getData()
                    _this.getPrice()
                    _this.loading = false
                  }
                }
              }
            }
        }catch{
            _this.eur = 25
            _this.getData()
            _this.getPrice()
            _this.loading = false
        }
    },
    methods:{
        sortMoney(a,b,isAsc){
            var aCena = a.cena.split('€')
            var bCena = b.cena.split('€')
            if(isAsc){ return aCena[1] - bCena[1] }
            else{ return bCena[1] - aCena[1] }
        },
        getData(){
            for (let i = 0; i < this.software.length; i++) {
                var cde = this.software[i]

                var ano = null
                var ne = null
                var xtn = null

                for (let ii = 1; ii <= 61; ii++) {
                    switch (this.dataSource[cde].data[ii]["Společné"]) {
                        case true: 
                            ano++
                            break
                        case false:
                            ne++
                            break
                        default: 
                            xtn++
                    }
                    switch (this.dataSource[cde].data[ii]["Web"]) {
                        case true: 
                            ano++
                            break
                        case false:
                            ne++
                            break
                        default: 
                            xtn++
                    }
                    switch (this.dataSource[cde].data[ii]["Desktop"]) {
                        case true: 
                            ano++
                            break
                        case false:
                            ne++
                            break
                        default: 
                            xtn++
                    }
                    switch (this.dataSource[cde].data[ii]["Mobil"]) {
                        case true: 
                            ano++
                            break
                        case false:
                            ne++
                            break
                        default: cde
                            xtn++
                    }
                }

            this.sw.push({cde, ano, ne, xtn});
            }
        },
        getPrice(){
            for (let i = 0; i < this.software.length; i++) {
                var cde = this.software[i]
                
                var cena1 = this.dataSource[cde].data[62]["Společné"]
                if(cena1.includes("€")){
                    //cena1 = parseFloat(cena1.replace(/\s/g, '')) * parseFloat(this.eur)
                    cena1 = parseFloat(cena1.replace(/\s/g, ''))
                }else if(cena1.includes("Kč")){
                    //cena1 = parseFloat(cena1.replace(/\s/g, ''))
                    cena1 = parseFloat(cena1.replace(/\s/g, '')) / parseFloat(this.eur)
                }else{
                    this.chybí.push(cde)
                    continue
                }

               var cena = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'EUR' }).format(cena1)
                this.cena.push({cde, cena})
            }
        },
        async getEUR(){
        try{
            var _this = this
            const xhr = new XMLHttpRequest()
            xhr.open('GET', 'https://cors.bridged.cc/https://www.ecb.europa.eu/stats/eurofxref/eurofxref-daily.xml', true, null, null)
            xhr.setRequestHeader('x-requested-With', 'XMLHttpRequest')
            xhr.setRequestHeader('Accept', 'application/json')
            xhr.send()

            xhr.onload = function () {
              if (xhr.readyState === xhr.DONE) {
                if (xhr.status === 200) {
                  const parser = new DOMParser()
                  const dom = parser.parseFromString(xhr.response, "application/xml")
                  try{
                      _this.eur = dom.querySelectorAll('[currency="CZK"]')[0].attributes.rate.nodeValue
                  }catch(e){
                      _this.eur = 25
                  }
                }
              }
            }
        }catch{
            _this.eur = 25
        }
        }
    }
}
</script>

<style>
.th-wrap{
    color: white!important;
    
}

/* .th-wrap.is-numeric{
    justify-content: flex-end;
} */
</style>