<template>
<div class="vertical-center-content">
  <div class="container cde-header">
    <!-- <div class="language-switch-home"><router-link to="/">CZ</router-link> | <router-link to="/en/">EN</router-link></div> -->
    <div class="cde-header-left">
      <a href="https://www.czbim.org/" target="_blank"><img :src="require('../assets/bim-logo.svg')" alt="Logo" class="cde-header-logo mb-4 mr-6" style="height: 50px"></a>
      <img :src="require('../assets/buildingsmart.png')" alt="BuildingSmart Czech Republic" class="cde-header-logo mb-4" style="height: 50px">
      <h1 class="is-size-2 mb-4 has-text-weight-medium is-family-secondary">Zorientujte se v <span class="has-text-primary">řešeních CDE</span></h1>
      <p class="mb-5">Shromáždili jsme informace o jednotlivých řešeních CDE, které jsme nahráli do naší databáze a verifikovali. Přitom jsme vycházeli z důležitých a nejvíce požadovaných vlastností pro různé typy činností, tj. od projekce, přes realizaci až po správu nemovitostí.</p>

      <div class="columns">
        <div class="column" style="padding-bottom: 0"><b-button expanded rounded type="is-light" tag="router-link" to="/CDE">Co je CDE?</b-button></div>
        <div class="column" style="padding-bottom: 0"><b-button expanded rounded type="is-light" tag="router-link" to="/projekt">O projektu</b-button></div>
        <div class="column" style="padding-bottom: 0"><b-button expanded rounded type="is-light" tag="router-link" to="/metodiky">Dostupné metodiky</b-button></div>
      </div>
      <div class="columns">
        <div class="column"><b-button expanded rounded type="is-primary" icon-right="arrow-right" tag="router-link" to="/vyber">Najdi CDE</b-button></div>
      </div>
    </div>

    <div class="cde-header-right">
      <img :src="require('../assets/cde.png')" alt="Najdi CDE">
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Uvod',
  metaInfo: {
    title: 'Úvod',
    titleTemplate: '%s | NajdiCDE.cz',
    meta: [
      { name: 'description', content: 'Shromáždili jsme informace o jednotlivých řešeních CDE, které jsme nahráli do naší databáze a verifikovali.'},
      { name: 'keywords', content: 'CDE, NajdiCDE, BIM, czBIM, software, analýza CDE, srovnání CDE'}
    ]
  }
}
</script>