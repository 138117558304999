<template>
  <div id="app">
    <div class="language-switch-home"><a @click.prevent="language('cz')">CZ</a> | <a @click.prevent="language('en')">EN</a></div>
    <!-- <div v-if="getCurrentLang()" class="language-switch-home">
      <b-tooltip type="is-primary-light" position="is-bottom" :auto-close="['outside', 'escape', 'inside']">
            <b-button type="is-primary-light"><span :class="currLang == 'cz' ? 'flag-icon flag-icon-cz' : 'flag-icon flag-icon-gb'"></span></b-button>
            <template v-slot:content>
                <span @click.prevent="language('cz')" class="flag-icon flag-icon-cz flag-dropdown"></span><br>
                <span @click.prevent="language('en')" class="flag-icon flag-icon-gb flag-dropdown"></span>
            </template>
        </b-tooltip>
    </div> -->

    <vue-page-transition name="fade">
      <router-view/>
    </vue-page-transition>
  </div>
</template>

<script>
export default {
  data() {
    return { 
      currLang: null
    }
  },
  methods:{
    // getCurrentLang(){
    //   if(this.$route.path.includes("en")){
    //     this.currLang = "en"
    //   }else{
    //     this.currLang = "cz"
    //   }
    //   return true
    // },
    language(lang){
      var currPath = this.$route.path.split('/')

      if(currPath[1] == 'en') this.currLang = 'en'
      else this.currLang = 'cz'

      if(this.currLang == 'cz'){
        if(lang=='cz'){
          this.$router.push({ path: currPath[0], query: this.$route.query })
        }
        else{
          this.$router.push({ path: lang+'/'+currPath[1], query: this.$route.query })
        }
      }else{
        if(lang=='cz'){
          if(!currPath[2]){
            this.$router.push({ path: '/'+currPath[0], query: this.$route.query })
          }else{
            this.$router.push({ path: '/'+currPath[2], query: this.$route.query })
          }
        }
        else{
          this.$router.push({ path: currPath[2], query: this.$route.query })
        }
      }   
    }
  }
}
</script>


<style lang="scss">
  @import './assets/styles/main.scss';

  .cde-header{
    display: flex;
    align-items: center;
  }

  .cde-header-left, .cde-header-right{
    width: 50%;
    margin: 10px;
  }

  .cde-header-logo{
    box-sizing: border-box;
    color: #0A47AC;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    height: 90px;
    line-height: 25.6px;
    margin-bottom: 1.5rem;
    vertical-align: middle;
  }

  .vertical-center-content{
    display: flex;
    align-items: center;
    height: 100vh;
  }
</style>