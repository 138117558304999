<template>
    <div class="container">
        <div class="card subpage">
            <div class="has-text-centered" style="display: flex; justify-content: space-between; align-items: center;">
                <div class="button-back-left"><b-button tag="router-link" to="/" type="is-primary" rounded icon-left="arrow-left"></b-button></div>
                <div class="subpage-heading is-family-secondary">
                    <p class="has-text-primary">NAJDICDE.CZ</p>
                    <h1 class="is-size-3 has-text-weight-semibold">404</h1>
                </div>
                <div class="button-back-right" style="visibility: hidden;"><b-button tag="router-link" to="/" type="is-primary" rounded icon-left="arrow-left"></b-button></div>
            </div>

            <!-- <hr>

            <div>
                <p class="has-text-centered">404 🤷</p>

            </div> -->
        </div>
    </div>
</template>

<script>
export default {
  name: 'NotFound',
  metaInfo: {
    title: '404',
    titleTemplate: '%s | NajdiCDE.cz',
    meta: [
      { name: 'description', content: 'Stránka nenalezena.'},
      { name: 'keywords', content: 'CDE, NajdiCDE, BIM, czBIM, software, analýza CDE, srovnání CDE'}
    ]
  }
}
</script>

<style scoped>

</style>