<template>
    <div class="container">
        <div class="card subpage">
            <div class="has-text-centered" style="display: flex; justify-content: space-between; align-items: center;">
                <div class="button-back-left"><b-button tag="router-link" to="/" type="is-primary" rounded icon-left="arrow-left"></b-button></div>
                <div class="subpage-heading is-family-secondary">
                    <p class="has-text-primary">NAJDICDE.CZ</p>
                    <h1 class="is-size-3 has-text-weight-semibold">Generátor</h1>
                </div>
                <div class="button-back-right" style="visibility: hidden;"><b-button tag="router-link" to="/" type="is-primary" rounded icon-left="arrow-left"></b-button></div>
            </div>

            <hr>
            <div>
                <h1 class="is-size-4 is-family-secondary">Excel > NajdiCDE</h1>
                <b-field grouped>
                    <b-input v-model="source" expanded type="textarea" @input="doStuff()"></b-input>
                    <b-input v-model="output" expanded type="textarea" readonly></b-input>
                </b-field>
                
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'Stats',
    data () {
        return {
            software: ["Trimble Connect", "BIM 360 Family", "BIMx", "BIMcloud", "Dalux Build", "Bim.Point", "Asite", "Proconom", "BIMPlus", "ProjectWise Plná verze", "ProjectWise 365", "AspeHub", "CADDS3"],
            source: null, output: null,
        }
    },
    methods:{
        doStuff(){
            console.log("Doing stuff.")

            var source = this.source
            var output = this.output

            var tab = RegExp("\\t", "g")

            output = source.replace(tab, "$")

            var array = output.split(/\r?\n/)
            var outputArray = null
            console.log(array)

            for (let i = 0; i < array.length; i++) {
                //const element = array[i];
                if(i == 0){
                    outputArray = "{"
                }else{
                    var tempArray = array[i].split("$")
                    if(!tempArray[1]) continue
                    outputArray = outputArray + `"${tempArray[0]}": { "Společné": ${this.g(tempArray[1])}, "Web": ${this.g(tempArray[2])}, "Desktop": ${this.g(tempArray[3])}, "Mobil": ${this.g(tempArray[4])} },`
                }
                
            }
            
            outputArray = outputArray + "}"
            this.output = outputArray
        },
        g(i){
            switch (i) {
                case "ano":
                    return true
                case "ne":
                    return false
                default:
                    return `"${i}"`
            }
        }
    }
}
</script>

<style>

</style>